import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container, { Col } from '../components/global/Container'
import PageLink from '../components/global/PageLink'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import RightArrowIcon from '../graphics/icons/right-arrow-icon.svg'
import { mQw } from '../utils/mediaQueries'
import Content, { HTMLContent } from '../components/global/Content'

const StoryWidth = styled.div`
  ${tw`m-auto`}
  @media (${mQw.lg}) {
    max-width: 640px;
  }
`

export const NewsPostTemplate = ({
  content,
  className,
  contentComponent,
  data,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <div className={className}>
      {helmet || ''}
      <Container tw="py-6 md:py-12">
        <Col>
          <PageLink to="/news" tw="flex text-sm md:text-base">
            <RightArrowIcon tw="transform rotate-180 mr-1" />
            All News
          </PageLink>
          <StoryWidth>
            <h1 tw="mt-8 mb-1 md:mb-3">{data.title}</h1>
            <small>{data.date}</small>
          </StoryWidth>
          {data.image &&
            <img 
              tw="w-full my-12"
              src={ 
                data.image.childImageSharp
                ? data.image.childImageSharp.fluid.src
                : data.image
              }
            />
          }
          <StoryWidth>
            <PostContent className="content" content={content} />
          </StoryWidth>
        </Col>
      </Container>
    </div>
  )
}

NewsPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentComponent: PropTypes.func,
  helmet: PropTypes.object,
  data: PropTypes.object.isRequired,
}

const NewsPostComponent = ({ data, className }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <NewsPostTemplate
        content={post.html}
        className={className}
        contentComponent={HTMLContent}
        data={post.frontmatter}
        helmet={
          <Helmet titleTemplate="%s - News">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

NewsPostComponent.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
  className: PropTypes.string
}

const NewsPost = styled(NewsPostComponent)`
  ${tw``}
`

export default NewsPost

export const pageQuery = graphql`
  query NewsPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      id
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageCaption
        date(formatString: "MMMM DD, Y")
      }
    }
  }
`
